.tabs_wrapper {
  position: relative;
  .content {
    display: flex;
    .item {
      margin-right: 40px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      color: var(--text-l1);
      font-size: 18px;
      font-family: Microsoft YaHei;
      transition: font-weight 0.3s;
    }
  }
  .link {
    transition: transform 0.3s;
    position: absolute;
    bottom: -6px;
    width: 16px;
    height: 4px;
    border-radius: 2px;
    background: var(--brand-l1);
  }
}

:global {
  .activated_item {
    font-weight: 700;
  }
}