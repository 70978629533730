.input_box {
  width: 100%;
  // height: 94px;
  .input_content {
    max-width: 800px;
    margin: 0 auto;
    padding: 16px 0;
  }
  .input_box_top {
    display: flex;
    justify-content: space-between;
    .input_top_left {

    }
    .input_top_right {
      display: flex;
      .vip_tool_item {
        margin-right: 12px;
        padding: 7px 12px;
        border-radius: 30px;
        background: #FFF;
        &:last-child {
          margin-right: 0;
        }
        span {
          margin-left: 6px;
          color: #606060;
          font-family: Microsoft YaHei;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .input_box_bottom {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input_box_realod {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid #DDE7FF;
      background: rgba(255, 255, 255, 0.85);
      overflow: hidden;
      user-select: none;
    }
    .input_box_right {
      flex: 1 1;
      margin-left: 12px;
      border-radius: 8px;
      border: 1px solid var(--outline-l-1);
      background: #FFF;
      // max-height: 80px;
      position: relative;
      textarea {
        resize: none;
        border-radius: 8px;
        border: none;
        width: 100%;
        min-height: 48px !important;
        outline: 0;
        font-size: 16px;
        padding-right: 120px;
        padding-top: 10px;
        color: var(--text-l1);
        &::placeholder {
          color: var(--text-note);
          font-size: 16px;
          font-family: Microsoft YaHei;
        }
        &:focus {
          box-shadow: 0px 0px 0px 2px #88BFFF;
        }
      }
      .input_box_right_btn_box {
        position: absolute;
        right: 8px;
        top: 50%;
        // bottom: 0;
        transform: translateY(-50%);
        div {
          cursor: pointer;
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
          }
          :global {
            .ant-btn {
              display: flex;
              align-items: center;
              border-radius: 4px;
              background: var(--brand-l1);
              border-color: var(--brand-l1) !important;
              color: #fff;
              .ant-btn-icon {
                margin-inline-end: 6px;
              }
            }
            .ant-btn-primary:disabled {
              background: var(--brand-disable);
              border-color: var(--brand-disable) !important;
            }
          }
        }
      }
    }
  }
}