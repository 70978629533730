.main {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 16px;
  background: linear-gradient(108deg, #438EFF 0%, #5299FF 100%);
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
}

// .left {
//   width: 400px;
//   height: 100%;
//   background-color: aquamarine;
// }

// .right {
//   flex: 1;
//   height: 100%;
// }