.code_box {
  color: var(--brand-l1);
  font-size: 16px;
  cursor: pointer;
}

.login_btn1 {
  :global {
    .ant-btn-default {
      line-height: normal;
      color: var(--black-66);
      font-size: 16px;
      padding: 0 24px;
      height: 45px;
      border-color: #EEE;
    }
  }
}
.login_btn2 {
  flex: 1;
  margin-left: 12px;
  :global {
    .ant-btn-primary {
      line-height: normal;
      width: 100%;
      font-size: 16px;
      padding: 0 16px;
      height: 45px;;
      color: #fff;
      &:disabled {
        background: var(--brand-disable);
        border-color: var(--brand-disable);
      }
    }
  }
}