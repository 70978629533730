.wrapper {
  padding-left: 20px;
  padding-right: 16px;
  height: 60px;
  background: rgba(255, 255, 255, 0.50);
  // border-bottom: 1px solid #F5F5F5;
  display: flex;
  justify-content: space-between;
  .filex_align_center {
    display: flex;
    align-items: center;
  }
  .left {
    display: flex;
    align-items: center;
    .back_box {
      cursor: pointer;
      color: var(--text-l2);
    }
  }
  .center {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .right {
    .avatar_box {
      height: 100%;
      .user_avatar_box {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .ant-popover-inner {
          border-radius: 12px !important;
        }
        .ant-popover-inner-content {
          padding: 0 !important;
        }
      }
      .no_login_box {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .no_login {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 20px;
        border: 0.5px solid #DDE7FF;
        background: #fff;
        height: 32px;
        .no_login_left {
          width: 32px;
          height: 32px;
        }
        .no_login_text {
          margin: 0 12px 0 8px;
          color: var(--text-l1);
          font-size: 14px;
        }
      }
    }
    // 下载按钮
    .download_box {
      user-select: none;
      display: flex;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;
      height: 32px;
      padding: 0px 12px;

      border-radius: 99px;
      border: 0.5px solid #DDE7FF;
      background: #fff;
      span {
        margin-left: 4px;
        font-size: 14px;
        color: var(--text-l1);
      }
    }
  }
}

.rq_code {
  border-radius: 8px;
  background: #fff;
  z-index: 99 !important;
  .rq_img {
    width: 130px;
    height: 130px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rq_text {
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--text-l1);
  }
}


.rq_popover {
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      border-radius: 8px;
      padding: 10px;
    }
  }
}

.popover {
  --min-width: 234px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 1px 20px 0px rgba(115, 115, 143, 0.03);
  z-index: 99;
  .popover_item {
    min-width: var(--min-width);
    padding: 16px;
    border-bottom: 1px solid #F5F5F5;
    background-color: #fff;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #F5F8FC;
    }
    .item_left {
      display: flex;
      align-items: center;
      .username {
        color: var(--text-l1);
        font-size: 16px;
        font-family: Microsoft YaHei;
      }
      .vip_img {
        width: 33px;
        height: 16px;
      }
      span {
        color: var(--text-l1);
        font-family: Microsoft YaHei;
        font-size: 14px;
      }
    }
    .item_right {
      span {
        color: var(--brand-l1);
        font-family: Microsoft YaHei;
        font-size: 14px;
        cursor: pointer;
      }
    }
    
    .sign_text {
      color: #FF7C32 !important;
    }
  }
}

.user_info_popover {
  // left: 50px !important;
    inset: 74px 23px auto auto !important;
  :global {
    padding-top: 0 !important;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      overflow: hidden;
      border-radius: 12px !important;
      padding: 0 !important;
    }
    .ant-popover-inner-content {
      padding: 0 !important;
    }
  }
}