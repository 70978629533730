
.pay_modal_box {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pay_modal_guidance {
    font-size: 18px;
    font-weight: bold;
  }
  .pay_modal_rq_box {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    img {
      width: 200px;
      height: 200px;
      // box-shadow: 0 0 5px rgba(0,0,0,0.1);
    }
  }
  .pay_modal_downlaod_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      text-decoration: underline;
    }
    div {
      // margin-left: 15px;
      cursor: pointer;
    }
    .copy_btn_box {
      margin-top: 10px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        border-radius: 8px;

        font-family: Microsoft YaHei;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        
        background-color: #4987FC;
        span {
          margin-left: 8px;
        }
      }
    }
  }
}