
.modal_box {
  display: flex;
  height: 520px;
  .modal_left {
    overflow: hidden;
    border-radius: 14px 0 0 14px;
    width: 748px;
    img {
      width: 100%;
      height: 100%;
      vertical-align: bottom;
    }
  }
  @media screen and (max-width: 960px) {
    .modal_left {
      display: none;
    }
  }
  .modal_right {
    min-width: 375px;
    padding: 20px 24px;
    background: linear-gradient(180deg, #F3F6FF 0%, #FFF 19.06%);
    .right_top_close {
      display: flex;
      justify-content: space-between;
      .right_top_title {
        font-size: 18px;
        color: var(--text-l1);
      }
      img {
        vertical-align: bottom;
        cursor: pointer;
      }
    }
  }
}

.login_modal {
  border-radius: 16px;
  :global {
    .ant-modal-content {
      overflow-x: auto;
      border-radius: 16px !important;
      border: 2px solid #fff;
      padding: 0 !important;
    }
  }
}
.login_btn1 {
  :global {
    .ant-btn-default {
      line-height: normal;
      color: var(--black-66);
      font-size: 16px;
      padding: 0 24px;
      height: 45px;
      border-color: #EEE;
    }
  }
}
.login_btn2 {
  flex: 1;
  margin-left: 12px;
  :global {
    .ant-btn-primary {
      line-height: normal;
      width: 100%;
      font-size: 16px;
      padding: 0 16px;
      height: 45px;;
      color: #fff;
      &:disabled {
        background: var(--brand-disable);
        border-color: var(--brand-disable);
      }
    }
  }
}