.wrapper {
  .content_wrapper {
    height: calc(100vh - (60px + 32px));
    overflow-y: auto;
  }
  .content {
    max-width: 800px;
    margin: 0 auto;
    padding: 24px 20px;
    .example_box {
      padding: 16px;
      border-radius: 8px;
      border: 1px solid var(--outline-l-1);
      background: #FCFCFC;

      display: flex;
      flex-direction: column;
      .example_title {
        color: var(--text-note);
        font-family: Microsoft YaHei;
        font-size: 16px;
        line-height: 24px;
      }
      .example_item {
        margin-top: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
          margin-left: 8px;
          color: var(--text-l1);
          font-family: Microsoft YaHei;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .input_box {
      margin-top: 16px;
      border-radius: 8px;
      border: 1px solid var(--outline-l-1);
      background: #FFF;
      height: 110px;
      textarea {
        // height: 110px;
        resize: none;
        border-radius: 8px;
        border: none;
        width: 100%;
        height: 100%;
        outline: 0;
        padding: 16px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        color: var(--text-l1);
        transition: box-shadow 0.4s;
        &::placeholder {
          color: var(--text-note);
          font-size: 16px;
          font-family: Microsoft YaHei;
        }
        &:focus {
          box-shadow: 0px 0px 0px 2px #88BFFF;
        }
      }
    }
    .btn_box {
      display: flex;
      margin-top: 12px;
      div {
        :global {
          .close_btn {
            border-color: var(--outline-l-1);
            color: var(--text-l2);
          }
          .submit_btn {
            background: var(--brand-l1);
          }
          .submit_btn:disabled {
            background: var(--brand-disable);
            border-color: var(--brand-disable);
            color: #fff;
          }
        }
        button {
          svg {
            margin-right: 10px;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 48px;
          border-radius: 8px;

          font-family: Microsoft YaHei;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &:nth-child(1) {
          width: 80px;
        }
        &:nth-child(2) {
          flex: 1;
          margin-left: 12px;
          button {
            background-color: var(--brand-l1);
          }
        }
      }
    }
    .ai_content_box {
      margin-top: 12px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #E5E5E5;
      background: #FFF;
    }
    .btn2_box {
      margin-top: 12px;
      display: flex;
      
      div {
        flex: 1;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 48px;
          border-radius: 8px;

          font-family: Microsoft YaHei;
          font-size: 18px;
          border-color: var(--outline-l-1);
          color: var(--text-l2);
          span {
            margin-left: 8px;
          }
        }
        &:nth-child(3) {
          button {
            background-color: var(--brand-l1);
            border-color: rgba(0, 0, 0, 0.10);
            color: #fff;
          }
        }
      }
    }
  }
}
