.history_box {
  height: 65vh;
  display: flex;
  flex-direction: column;
  .his_input_box {
    padding-bottom: 10px;
  }
  .his_list {
    padding-right: 24px;
    flex: 1 1;
    overflow-y: auto;
    // margin-top: 32px;
    .empty_box {
      padding: 100px;
    }
    .his_list_item {
      // margin-bottom: 24px;
      padding: 16px 0;
      border-bottom: 1px solid #F5F5F5;
      &:nth-child(1) {
        padding-top: 22px;
      }
      .his_item_title {
        width: 100%;
        color: var(--black-22);
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .his_item_ai {
        margin: 8px 0;
        color: #666;
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        // -webkit-line-clamp: 4;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .his_item_tool {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .his_item_left {
          .his_item_left_time {
            color: #666;
            font-family: Microsoft YaHei;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .his_item_right {
          display: flex;
          .icon {
            display: flex;
            align-items: center;
            margin-left: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.his_input_box {
  :global {
    .ant-input-affix-wrapper {
      background-color: #F8FAFC;
      border-radius: 4px;
    }
    .ant-input {
      background-color: #F8FAFC;
    }
  }
}

.my_modal_wrapper {
  // background-color: red !important;
  :global {
    .ant-modal {
      min-width: 800px !important;
      width: 800px !important;
    }
  }
}