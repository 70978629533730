.right_box {
  cursor: pointer;
}
.retrieve_password {
  text-align: right;
  margin-top: 12px;
  span {
    color: var(--text-note);
    font-size: 16px;
    cursor: pointer;
  }
}