.input_wrapper {
  background: #F7FAFE;
  border: 1px solid var(--LightBack);
  padding: 0 12px;
  border-radius: 4px;
  height: 43px;
  // 去掉输入框数字的上下箭头默认样式
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.input {
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  flex: 1;
  font-size: 16px;
  color: var(--text-l1);
  &::placeholder {
    color: var(--text-note);
    font-size: 16px;
  }
}