.wrapper {
  width: 245px;
  max-height: calc(100vh - 32px);
  background: rgba(255, 255, 255, 0.75);
  border-radius: 12px 0px 0px 12px;
  border-right: 1px solid #F2F7FF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .logo_box {
    width: 100%;
    padding: 20px 0 8px 16px;
    display: flex;
    align-items: center;
    img {
      user-select: none;
      -webkit-user-drag: none;
    }
    .aiq_logo_text {
      margin-left: 5px;
    }
  }
  .entry_box {
    margin: 16px 0;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .entry_item {
      user-select: none;
      display: flex;
      width: 100%;
      padding: 16px 0;
      padding-left: 16px;
      margin-bottom: 4px;
      box-sizing: border-box;
      &:last-child {
        margin-bottom: 0;
      }
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;

      color: var(--text-l2);
      font-family: Microsoft YaHei;
      font-size: 16px;
      font-weight: 700;
      &:hover {
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.4);
      }
    }
    .entry_activate_item {
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.85);
      color: var(--brand-l1);
    }
  }
  .session_box {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
    .session_title_box {
      display: flex;
      padding: 16px;
      border-top: 1px solid rgba(241, 247, 255, 0.30);
      span {
        user-select: none;
        color: #617CA4;
        font-family: Microsoft YaHei;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .session_list {
      padding: 0 16px;
      flex: 1 1;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      &:hover {
        &::-webkit-scrollbar {
          display: block;
        }
      }
      .session_item {
        user-select: none;
        width: 100%;
        padding: 12px;
        display: flex;
        cursor: pointer;
        margin-bottom: 4px;
        .item_close {
          display: none;
        }
        &:hover {
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.40);
          .item_close {
            display: flex;
          }
        }
        .session_item_left {
          width: 43px;
          height: 43px;
          // background: #D9D9D9;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            border-radius: 45px;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .session_item_right {
          margin-left: 12px;
          display: flex;
          align-items: center;
          .session_item_title {
            color: var(--text-l2);
            font-size: 16px;
          }
        }
      }
      .session_activate_item {
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.85);
        .session_item_title {
          color: var(--brand-l1) !important;
        }
      }
    }
    .close_box {
      padding: 16px;
    }
    .close_all {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 10px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.05);
      background: rgba(255, 255, 255, 0.85);
      cursor: pointer;
      span {
        margin-left: 12px;
        color: var(--text-l2);
        font-size: 16px;
        font-family: Microsoft YaHei;
      }
    }
  }
}

.empty_box {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .empty_img {
    user-select: none;
    -webkit-user-drag: none;
  }
  .empty_text {
    color: #617CA4;
    font-size: 14px;
    user-select: none;
  }
  .empty_btn {
    margin-top: 54px;
    padding: 8px 12px;
    border-radius: 4px;
    background: #fff;
    color: var(--text-l2);
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
}