
.agreement_box {
  display: flex;
  font-size: 16px;
  .agreement_left {
    span {
      color: #606060;
      font-family: Microsoft YaHei;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
    }
  }
  .agreement_right {
    span {
      color: var(--brand-l1);
      font-family: Microsoft YaHei;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }
  }
}
