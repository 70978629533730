.title {
  font-weight: 700;
  font-size: 20px;
  color: var(--text-l1);
  text-align: center;
}
.agreement_modal {
  top: 16px !important;
}
.content {
  padding-right: 16px;
  height: calc(100vh - 118px);
  overflow-y: auto;
}