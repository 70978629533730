.wrapper {
  display: flex;
  .vip_tool_item {
    cursor: pointer;
    margin-right: 12px;
    padding: 8px 12px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.75);
    &:last-child {
      margin-right: 0;
    }
    span {
      margin-left: 6px;
      color: var(--text-l2);
      font-family: Microsoft YaHei;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}