:root {
  --text-l1: #2D374C;
  --text-l2: #4A5D82;
  --text-note: #8E9DBA;
  --black-22: #222;
  --brand-l1: #0A78F9;
  --brand-disable: #AACCFF;
  --black-66: #666;
  --alarm-l-1: #E74E4E;
  --outline-l-1: #DDE7FF;
  --LightBack: #F2F6FC;
}

.color-text-l1 {
  color: var(--text-l1);
}

.color-text-note {
  color: var(--text-note);
}


.header_title_text {
  color: var(--text-l1);
  font-family: Microsoft YaHei;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.out_login_text {
  color: var(--alarm-l-1) !important;
  font-family: Microsoft YaHei;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scrollbar_style {
  /* 定义滚动条样式 */
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
  }
  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--brand-disable);
  }
}

.none_scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}
