.wrapper {
  .content {
    // padding-top: 24px;
    height: calc(100vh - 60px - 32px);
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 10px;
    // padding-top: 24px;
    .filter_box {
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
      .filter_item {
        user-select: none;
        margin: 0 15px 15px 0;
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid var(--outline-l-1);
        background: #FFF;
        cursor: pointer;
        span {
          color: var(--text-l1);
          font-family: Microsoft YaHei;
          font-size: 16px;
        }
        &:hover {
          border: 1px solid var(--brand-l1);
          span {
            color: var(--brand-l1);
          }
        }
      }
      .activate_filter_item {
        border: 1px solid var(--brand-l1);
        span {
          color: var(--brand-l1);
        }
      }
    }
    .filter_content_scroll {
      flex: 1 1;
      overflow-y: auto;
    }
    .filter_content {
      // max-height: 78vh;
      // max-height: calc(99vh - var(--filter-offsettop));
      margin-top: 17px;
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
      // gap: 32px;
      .filter_con_item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 12px;
        margin-bottom: 32px;
        margin-right: 32px;
        border-radius: 8px;
        border: 1px solid var(--outline-l-1);
        background: rgba(255, 255, 255, 0.85);
        .f_c_i_r_t_icon {
          display: none;
        }
        &:hover {
          background-color: #fff;
        }
        &:hover .f_c_i_r_t_icon {
          display: block;
        }
        .filter_con_item_left {
          // background-color: #D9D9D9;
          // border-radius: 50%;
          // display: flex;
          // align-items: center;
          .filter_con_i_l_icon {
            width: 65px;
            height: 65px;
            border-radius: 50%;
          }
        }
        .filter_con_item_right {
          flex: 1;
          margin-left: 16px;
          .filter_c_i_r_top {
            // height: 22px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .f_c_i_r_t_text {
              color: var(--text-l1);
              overflow: hidden;
              text-overflow: ellipsis;
              whitespace: nowrap;
              font-family: Microsoft YaHei;
              font-size: 16px;
            }
          }
          .filter_c_i_r_bottom {
            margin-top: 8px;
            .f_c_i_r_b_text {
              width: 254px;
              color: #8C8D8F;
              font-family: Microsoft YaHei;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              // line-height: 150%;
              
              display: -webkit-box;
              -webkit-line-clamp:2;
              -webkit-box-orient: vertical;
              overflow: hidden; 
            }
          }
        }
      }
    }
  }
}