
.msg_item {
  --max-width: 700px;
  .msg_item_user {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    .msg_user_content {
      max-width: var(--max-width);
      padding: 12px;
      border-radius: 8px;
      border: 0.5px solid rgba(0, 0, 0, 0.10);
      background: var(--brand-l1);
      color: #fff;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
  .msg_item_ai {
    margin-top: 16px;
    display: flex;
    .msg_item_wrapper {
      position: relative;
      .msg_ai_content {
        max-width: var(--max-width);
        padding: 12px;
        border-radius: 8px;
        border: 0.5px solid var(--outline-l-1);
        background: #FFF;
        position: relative;
        display: inline-block;
      }
      .more_box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -95px;
        display: flex;
        div {
          margin-right: 8px;
          cursor: pointer;
        }
        .more_item {
          border-radius: 50%;
          &:hover {
            background-color: #fff;
          }
        }
        .praise_box {
          border-radius: 50%;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: #fff;
          }
        }
      }
      .msg_ai_tool {
        // position: absolute;
        display: flex;
        margin-top: 8px;
        .msg_time {
          color: var(--text-note);
          font-family: Microsoft YaHei;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
.more_popover1 {
  :global {
    padding-top: 0 !important;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      padding: 0;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid var(--outline-l-1);
      background: #FFF;
      box-shadow: 0px 10px 20px 0px rgba(115, 115, 143, 0.03);
    }
    .ant-popover-inner-content {
      padding: 0 !important;
    }
  }
}

.prpover_content {
  .prpover_con_item {
    min-width: 138px;
    padding: 16px;
    display: flex;
    align-items: center;
    background: #FFF;
    border-bottom: 1px solid #F5F5F5;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: #F5F8FC;
    }
    span {
      margin-left: 8px;
      color: var(--text-l1);
      font-family: Microsoft YaHei;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .delete_text {
      color: var(--alarm-l-1);
    }
  }
}