.wrapper {
  flex: 1 1;
  overflow-y: auto;
  .list_content {
    max-width: 800px;
    margin: 0 auto;
  }
}

.divider {
  :global {
    .ant-divider-inner-text {
      color: #8C8D8F;
      text-align: center;
      font-family: Microsoft YaHei;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}